import { cva, type VariantProps } from 'class-variance-authority'
import * as React from 'react'

import { cn } from '#app/utils/misc'

const badgeVariantDefinitions = {
	transparent: 'bg-transparent border-button dark:border-gray-400/20',
	gray: 'bg-gray-50 dark:bg-gray-400/10 text-gray-600 dark:text-gray-400 border-gray-500/10 dark:border-gray-400/20',
	grayNoBorder:
		'border-none bg-[#EDEDED] text-[#89888E] dark:text-gray-500 text-medium',
	white: 'bg-white dark:bg-gray-50 text-gray-700 dark:text-gray-600 border-gray-500/10 dark:border-gray-400/20',
	green: 'bg-green-50 dark:bg-green-500/10 text-green-700 dark:text-green-400 border-green-600/20 dark:border-green-400/20',
	greenAlt: 'bg-[#E9F5EB] text-[#49AB5E] border-[#49AB5E]/20',
	yellow: 'bg-yellow-50 dark:bg-yellow-400/10 text-yellow-800 dark:text-yellow-500 border-yellow-600/20 dark:border-yellow-400/20',
	yellowOrange: 'bg-[#FDF5E1] text-[#F1AE04] border-[#FDF5E1]/20',
	blue: 'bg-blue-50 dark:bg-blue-400/10 text-blue-700 dark:text-blue-400 border-blue-700/10 dark:border-blue-400/30',
	lightBlue: 'bg-[#EFF6FF] text-[#1D4ED8] border-[#1D4ED81A]',
	red: 'bg-red-50 dark:bg-red-400/10 text-red-700 dark:text-red-400 border-red-600/10 dark:border-red-400/20',
	redSmall:
		'bg-red-50 dark:bg-red-400/10 text-red-700 dark:text-red-400 border-red-600/10 dark:border-red-400/20 px-2 py-0.5 text-xs',
	redAlt: 'bg-[#FDECEC] text-[#EB6363] border-[#EB6363]/10',
	indigo: 'bg-indigo-50 dark:bg-indigo-400/10 text-indigo-700 dark:text-indigo-400 border-indigo-700/10 dark:border-indigo-400/30',
	primary: 'bg-primary/10 text-primary border-primary/20',
	branded:
		'bg-gradient-to-r from-brand-start to-brand-end text-white border-transparent',
} as const

export type BadgeVariant = keyof typeof badgeVariantDefinitions

const badgeVariants = cva(
	'inline-flex items-center rounded-full px-3 py-1.5 border leading-none justify-center',
	{
		variants: {
			variant: badgeVariantDefinitions,
		},
		defaultVariants: {
			variant: 'gray',
		},
	},
)

export interface ButtonProps
	extends React.ButtonHTMLAttributes<HTMLDivElement>,
		VariantProps<typeof badgeVariants> {}

const Badge = React.forwardRef<HTMLDivElement, ButtonProps>(
	({ className, variant, children, ...props }, ref) => {
		return (
			<div
				className={cn(badgeVariants({ variant, className }))}
				ref={ref}
				{...props}
			>
				{children}
			</div>
		)
	},
)
Badge.displayName = 'Badge'

export { Badge, badgeVariants }
